<template>
  <div class="page">
    <Viewheader  :isHome="false" ref="navheader"/>
    <!-- banner图 -->
    <BannerHead
      :bannerImg="bannerImg"
      :bigTitle_1="bigTitle_1"
      :smallTitle_1="smallTitle_1"
    ></BannerHead>

    <!-- 核心功能 -->
    <CoreFunction :spanList="spanList" :coreImg="coreImg"></CoreFunction>

    <!-- 能力展示 -->
    <p class="big_titles">能力展示</p>
   
   <div class="operationWidth">
        <div class="operationBackgroundColor">
            <div  class="operationLeft" :class="{ 'prohibitClicking': prohibitClicking == true }">
                <div class="operationLeftMain" 
                    v-for="(item,index) in exampleDiagram" 
                    :key="index"
                    @click="selectPicture(index,item.img)"
                    :class="{ 'imgActive': imgCurrentActive === index }"
                >
                    <img :src="item.img"/>
                </div>
                
            </div>
            <div class="operationCenter">
                <!-- 成功图片 -->
                <div v-if="successImg" class="successImgProduct">
                    <div class="flatRecognitionPositioning">
                      <img :src="this.imgUrl" ref="image">
                      <div :style="{ width: `${this.ResData.width}px`, height:`${this.ResData.lineHeight}px`,position: `absolute`,top:`${this.ResData.lineTop}px`,left:`${this.ResData.lineLeft}px`}" class="lineBorder" v-if="xyPos"></div>
                    </div>
                </div>
                <!-- 报错图片 -->
                <div class="errMain" v-if="errImg">
                    <div class="errImg">
                      <img src="../../assets/img/err.png">
                    </div>
                    <div class="errText">
                      {{errText}}
                    </div>
                </div>
                
                <!-- 加载特效 -->
                <div class="loadSpecialEffects" v-if="loadImg"></div>

                <!-- 扫描脚框 -->
                <div class="top" v-if="loadImg"></div>
                <div class="bottom" v-if="loadImg"></div>
            </div>
            <div class="operationYellowRight">
                <div class="recognitionResult">
                    识别结果：
                </div>
                <div   class="brandsResult">
                  <div class="confidenceLevel">
                      品牌：{{ this.brandsClassName }}
                  </div>
                  <div class="confidenceLevel">
                      置信度：{{ this.brandsConfidence }}
                  </div>
                </div>
            </div>
        </div>
        <div class="operationDescriptionLanguage">
          1、模型⽬前仅⽀持png、jpg、jpeg；<br/> 2、模型结果对google系列产品（shopping、⾕歌seo等），进⾏了针对性优化，在此场景下使⽤效果更优。
        </div>
        <div class="actionText">
            <div class="detectionText">
                <input type="text" placeholder="粘贴网络图片URL" v-model="urlAdress">
                <button class="detectionButton" @click="brandIdentification(1)"  :disabled="this.urlAdress == ''">检测</button>
            </div>
            <div class="localUpload">
                  上传文件
                  <el-upload
                    :action="action"
                    accept="image/jpeg,image/jpg,image/png"
                    :show-file-list="false"
                    class="uploadFileType"
                    list-type="picture-card"
                    :on-success="getResultImg"
                    :headers="headers"
                    :on-change="handleChange"
                  >
                  </el-upload>
            </div>
        </div>
     </div>
    <!-- 差异优势 -->
    <Different :diffImg="diffImg" :diffList="diffList"></Different>

    <!-- 应用场景 -->
    <Application :appImg="appImg" :appliList="appliList"></Application>

     <!-- 尾部 -->
     <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from "@/components/centerPage/banner_head.vue";
import CoreFunction from "@/components/centerPage/core_function.vue";
import Different from "@/components/centerPage/differential_adv.vue";
import Application from "@/components/centerPage/application.vue";

import top10 from "@/assets/img/gongneng/logo/toubu.png";
import he10 from "@/assets/img/gongneng/logo/hexin.jpg";
import cha10 from "@/assets/img/gongneng/logo/chayi.png";
import app10 from "@/assets/img/gongneng/logo/changjing.png";

// foote组件
import { apiUri } from "../../api/txt";
import { multiLogoRecognition } from "@/api/aidata.js";

// 公用组件
import "../../assets/css/public.css";
import "../../assets/css/imgPublic.css";

// 头部
import Viewheader from '../../components/viewheader/viewheader.vue'
// foote组件
import Footering from "../../components/foot/viewfooter.vue";
export default {
  components: {
    BannerHead,
    CoreFunction,
    Different,
    Application,
    Viewheader,
    Footering
  },
  data() {
    return {
      bannerImg: top10,
      coreImg: he10,
      bigTitle_1: "细粒度品牌识别",
      smallTitle_1:
        '识别商品图片中品牌logo，支持跨境电商常见的320种品牌（持续扩展）',

      // 核心功能
      spanList: [
        {
          firstSpan: "商品logo识别",
          secondSpan:
            "识别320种跨境商品logo，可准确识别图片中品牌logo的名称和所在图片中的位置。",
        },
      ],

      //   差异优势
      diffImg: cha10,
      diffList: [
        {
          diffFirst: "覆盖度",
          diffSecond:
            "320种品牌基本涵盖境外常用品牌，且覆盖全品类，基本满足业务场景需求。",
        },
      ],
      
      //   应用场景
      appImg: app10,
      appliList: [
        {
          appliFirst: "电商平台/店铺安全",
          appliSecond:
            "识别商品品牌，实现平台商品准入及风险管控。",
        },
      ],

       // 能力展示
      // header请求
      action:`${apiUri}/img/multiLogoRecognition`,
      imgUrl: "",
      conf:'',
      result:'',
      headers:{
            token:localStorage.getItem("tokenKey")?localStorage.getItem("tokenKey"):'',
            isSample:1
      },
      // 示例图列表
      exampleDiagram:[
      {
        img:'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/20230531_185434.jpeg'
      },{
        img:'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/logo_050e4d81f07e5d.jpg'
      },{
        img:'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/16814842510799693_g_800X800.jpeg'
      },{
        img:'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/logo_hompog.jpg'
      },{
        img:'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/new-watches-2023-1908-gold-white-dial-m52508-0006_2301jva_001.jpeg'
      }],
      // 初始化选中状态
      imgCurrentActive:0,
      // 初始化选中图
      resultGraphImg:'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/20230531_185434.jpeg',
      // 成功图
      successImg:false,
      // 失败图
      errImg:true,
      // 正在加载
      loadImg:true,
      // 加载文案
      errText:"处理中....",
      urlAdress:"",
      // 加载时，禁止点击
      prohibitClicking:false,
      // 优化后按钮
      afterOptimization:false,
      brandsClassName:'',
      brandsConfidence:'',
      // 边框宽
      lineWidth:'',
      // 边框高
      lineHeight:'',
      // 上距离
      lineTop:'',
      // 左距离
      lineLeft:'',
      xyPos:false,
      isSample:'',
      ResData:[],
      imageWidth:'',
      imageHeight:''
    };
  },
  computed: {},
  mounted() {
     // 默认点击高亮
     this.selectPicture(this.imgCurrentActive,this.resultGraphImg)
  },
  methods: {
    // 获取缩小图片的宽度
    // getScaledSize() {
    //   // 缩小后高度
    //   this.imageWidth = this.$refs.image.offsetWidth;
    //   console.log(this.imageWidth)
    //   this.imageHeight = this.$refs.image.offsetHeight;
    //   console.log(this.imageHeight)
    // },
    // 点击选中高亮
    selectPicture(index,img) {
        this.resultGraphImg = img
        this.imgCurrentActive = index
        this.brandIdentification(0,this.resultGraphImg)
    },
    // 品牌识别url  请求
    brandIdentification(status,resultGraphImg){
        // 成功图
        this.successImg = false
        // 失败图
        this.errImg = true
        // 正在加载
        this.loadImg = true
        this.errText = '处理中....'


        if(status==0){
          // 点击图片获取地址
          var imgUrl = resultGraphImg
          // 加载时禁止点击切换
          this.prohibitClicking = true
          this.isSample = 0
        }else{
          // 点击检测按钮  获取地址
          var imgUrl = this.urlAdress;
          this.imgCurrentActive = -1;
          this.isSample = 1
        }
        
          // 请求接口-------------------
          multiLogoRecognition({  
            imgUrl:imgUrl,
            isSample: this.isSample
          }).then(({data}) => {
              if (data.state === "0x0000") {
                  setTimeout(() => {
                      this.urlAdress = ''
                      // 成功图
                      this.successImg = true
                      this.imgUrl = data.data.imgUrl

                      // y、x轴定位
                      var ResData = data.data.results
                     
                      if(ResData!=undefined){
                          
                          // var  ResData = data.data
                          // console.log(ResData[0]);
                          this.brandsClassName = ResData[0].className
                          this.brandsConfidence = ResData[0].confidence

                          // 获取原始图片宽高 \根据坐标画边框
                          var _this = this
                          var img = new Image();
                          img.src=data.data.imgUrl
                         
                          img.onload = function() { 
                              // 缩小后高度
                              // console.log(_this.$refs.image.offsetWidth)
                              // console.log(_this.$refs.image.offsetHeight)    
                              // 计算边框位置
                              var reductionFactor = _this.$refs.image.offsetWidth/img.width //缩小倍数
                              console.log('reductionFactor',reductionFactor);
                              // 获取坐标位置
                              // 边框宽
                              var lineWidth = (ResData[0].coordinate.xmax-ResData[0].coordinate.xmin)*reductionFactor
                              _this.$set(data.data, 'width', lineWidth)
                              // 边框高
                              var lineHeight = (ResData[0].coordinate.ymax-ResData[0].coordinate.ymin)*reductionFactor
                              _this.$set(data.data, 'lineHeight', lineHeight)
                              // 上距离
                              var lineTop = ResData[0].coordinate.ymin*reductionFactor
                              _this.$set(data.data, 'lineTop', lineTop)
                              // 左距离
                              var lineLeft = ResData[0].coordinate.xmin*reductionFactor
                              _this.$set(data.data, 'lineLeft', lineLeft)
                              // console.log(data.data)
                              _this.ResData = data.data
                              _this.xyPos = true

                          }   
                        
                      }else{
                          this.xyPos = false
                          this.brandsClassName = ''
                          this.brandsConfidence = ''
                      }
                       
                      // 失败图
                      this.errImg = false
                      // 正在加载
                      this.loadImg = false
                      // 加载完成  可点击切换
                      this.prohibitClicking = false
                    }, 1000);
                }else if(data.state === "0x0008"){
                    this.$message.error(data.message)
                    this.$refs.navheader.logOn()
                // }else if(data.state === "00001"){
                }else{
                  setTimeout(() => {
                      this.urlAdress = ''
                      this.imgUrl = ''
                      this.brandsClassName = ''
                      this.brandsConfidence = ''
                      // 成功图
                      this.successImg = false
                      // 失败图
                      this.errImg = true
                      this.errText = data.message
                      this.loadImg = false
                      // 加载完成  可点击切换
                      this.prohibitClicking = false
                    }, 1000);
                }
            }
          );
        
    },
    // 图片change操作
    handleChange(file, fileList){
          // 成功图
          this.successImg = false
          // 失败图
          this.errImg = true
          // 正在加载
          this.loadImg = true
          this.errText = '处理中....'
          this.imgCurrentActive = -1;
          // 加载时禁止点击切换
          this.prohibitClicking = true
    },
    // 图片上传成功后的回调
    getResultImg(response, file, fileList) {
          
          if (response.state === "0x0000") {
              setTimeout(() => {
                  this.imgUrl = response.data.imgUrl
                  
                  // y、x轴定位
                  var  ResData = response.data.ResData
                  if(ResData!=undefined){
                      var ResData = JSON.parse(response.data.ResData)
                      this.brandsClassName = ResData.Result[0].ClassName
                      this.brandsConfidence = ResData.Result[0].Confidence
                      // // 获取原始图片宽高 \根据坐标画边框
                      // var img = new Image();
                      // img.src=response.data.imgUrl
                      
                      
                      // this.$nextTick(function () {
                      //      // 计算边框位置
                      //     var reductionFactor = 620/img.width //缩小倍数
                      //     // 获取坐标位置
                      //     // 边框宽
                      //     this.lineWidth = (ResData.Result[0].Coordinate.Xmax-ResData.Result[0].Coordinate.Xmin)*reductionFactor
                      //     // 边框高
                      //     this.lineHeight = (ResData.Result[0].Coordinate.Ymax-ResData.Result[0].Coordinate.Ymin)*reductionFactor
                      //     // 上距离
                      //     this.lineTop = ResData.Result[0].Coordinate.Ymin*reductionFactor
                      //     // 左距离
                      //     this.lineLeft = ResData.Result[0].Coordinate.Xmin*reductionFactor
                      //     this.xyPos = true
                      // })
                       // 获取原始图片宽高 \根据坐标画边框
                        var _this = this
                        var img = new Image();
                        img.src=response.data.imgUrl
                        img.onload = function() {
                              // 计算边框位置
                              var reductionFactor =  _this.$refs.image.offsetWidth/img.width //缩小倍数
                              // 获取坐标位置
                              // 边框宽
                              var lineWidth = (ResData.Result[0].Coordinate.Xmax-ResData.Result[0].Coordinate.Xmin)*reductionFactor
                              _this.$set(response.data, 'width', lineWidth)
                              // 边框高
                              var lineHeight = (ResData.Result[0].Coordinate.Ymax-ResData.Result[0].Coordinate.Ymin)*reductionFactor
                              _this.$set(response.data, 'lineHeight', lineHeight)
                              // 上距离
                              var lineTop = ResData.Result[0].Coordinate.Ymin*reductionFactor
                              _this.$set(response.data, 'lineTop', lineTop)
                              // 左距离
                              var lineLeft = ResData.Result[0].Coordinate.Xmin*reductionFactor
                              _this.$set(response.data, 'lineLeft', lineLeft)
                              // console.log(response.data)
                              _this.ResData = response.data
                              _this.xyPos = true

                          }  

                      
                  }else{
                      this.xyPos = false
                      this.brandsClassName = ''
                      this.brandsConfidence = ''
                  }
                  
                  
                  // 成功图
                  this.successImg = true
                  // 失败图
                  this.errImg = false
                  // 正在加载
                  this.loadImg = false
                  // 加载完成  可点击切换
                  this.prohibitClicking = false
              }, 1000);
                    
          }else if(response.state === "0x0008"){
              this.$message.error(response.message)
              this.$refs.navheader.logOn()
          }else{
              setTimeout(() => {
                  this.imgUrl = ''
                  
                  this.brandsClassName = ''
                  this.brandsConfidence = ''
                  // 成功图
                  this.successImg = false
                  // 失败图
                  this.errImg = true
                  this.errText = response.message
                  this.loadImg = false
                  // 加载完成  可点击切换
                  this.prohibitClicking = false
              }, 1000);
          }
    },
  },
};
</script>

<style></style>
